<template>
  <div>
    <employee-classification-list></employee-classification-list>
  </div>
</template>

<script>
export default {
}
</script>


